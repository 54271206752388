/* General container styles */
.emi-calculator {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Heading style */
h2 {
    text-align: center;
    margin-bottom: 20px;
}

/* Form container styles */
.emi-form {
    width: 50%;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 20px;
    background-color: #68b7e9;
    box-sizing: border-box;
}

/* Table for form layout */
.form-table {
    width: 100%;
    border-collapse: collapse;
}

/* Table cells and headers for form */
.form-table td, .form-table th {
    padding: 10px;
    border: 0;
    vertical-align: middle;
}

.form-table th {
    text-align: left;
    font-weight: bold;
}

.form-table .label-cell {
    width: 30%;
    text-align: left;
    font-weight: bold;
}

.form-table .input-cell {
    width: 70%;
    text-align: left;
}

/* Input and select field styles */
input, select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Tenure input and select dropdown styles */
.tenure-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tenure-fields {
    display: flex;
    gap: 10px;
    width: 100%;
}

.tenure-fields input[type="number"] {
    width: 80px;
    margin-top: 5px;
}

/* Button styles */
button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.5s;
    display: block;
    margin: 20px auto 0;
}

button:hover {
    background-color: #45a049; /* Default color for hover effect */
}

/* Specific button styles */
.green-button {
    background-color: #078d0d;
    color: white;
}

.green-button:hover {
    background-color: #056c03;
}

.red-button {
    background-color: #dc3545;
    color: white;
}

.red-button:hover {
    background-color: #c82333;
}

/* EMI table styles */
.emi-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    text-align: center;
}

.emi-table th, .emi-table td {
    padding: 15px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.emi-table th {
    background-color: #f4f4f4;
}

/* Export section styles */
.export-section {
    margin-top: 25px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    max-width: 500px;
    height: 50px;
    gap: 10px;
}

.export-section button {
    padding: 10px;
    font-size: 16px;
    background-color: #4c66af;
    color: white;
    border: none;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s;
}

.export-section button:hover {
    background-color: #3b54a6;
}

/* Calendar widget styles */
.calendar {
    width: 150px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Error message styling */
.error-message {
    color: red;
    font-size: 0.875rem;
    margin-bottom: 5px; /* Space between error message and input */
}

/* Modal styles */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
