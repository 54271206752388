.App {
  text-align: center;
  padding: 20px;
}

table {
  margin-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

label {
  margin-right: 10px;
}

input, select {
  margin-right: 10px;
  padding: 5px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

h2 {
  margin-bottom: 20px;
}
